import React from 'react';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react'
import { API_URL } from 'assets';
import './Financial.scss'

import Hero from './components/Hero/Hero';
// import HeroAnimated from './components/HeroAnimated/HeroAnimated';
// import HeroAnimated2 from './components/HeroAnimated2/HeroAnimated2';
import NationallyRecognized from './components/NationallyRecognized/NationallyRecognized';
import ClientOfferings from './components/ClientOfferings/ClientOfferings';
import ClientTrust from './components/ClientTrust/ClientTrust';
import Expertise from './components/Expertise/Expertise';
import Services from './components/Services/Services';
import WorkShowcase from './components/WorkShowcase/WorkShowcase';
import BrandExperience from './components/BrandExperience/BrandExperience';
import LetsConnect from './components/LetsConnect/LetsConnect';
import Footer from './components/Footer/Footer';

export default function Financial() {
  const [financialContent, setFinancialContent] = useState(null);

  useEffect(() => {
    const getFinancialContent = async () => {
      const response = await fetch(`${API_URL}/pages/723?_embed=true&v=34`);
      const data = await response.json();
      setFinancialContent(data);
    }
    getFinancialContent();
  }, [])

  if (!financialContent) return null;

  return (
    <>
      <Helmet bodyAttributes={{ class: 'financial' }}>
        <title>Financial Capabilities | Third Rail Creative</title>
        <meta name="description" content="Helmet application" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
      </Helmet>
      <div className="financial">
        <div className="financial-introduction">
          <Hero data={financialContent.acf.hero_content} />
          {/* <HeroAnimated data={financialContent.acf.hero_content} /> */}
          {/* <HeroAnimated2 data={financialContent.acf.hero_content} /> */}
          <NationallyRecognized data={financialContent.acf.nationally_recognized_content} />
          <WorkShowcase data={financialContent.acf.work_showcase_content} />
          <ClientOfferings data={financialContent.acf.client_offerings_content} />
        </div>
        <ClientTrust data={financialContent.acf.client_trust_content} />
        <Expertise data={financialContent.acf.expertise_content} />
        <Services data={financialContent.acf.services_content} />
        {/* <WorkShowcase data={financialContent.acf.work_showcase_content} /> */}
        <BrandExperience data={financialContent.acf.brand_experience_content} />
        <LetsConnect data={financialContent.acf.lets_connect_content} />
        <Footer data={financialContent.acf.footer_content} />
      </div>
    </>
  );
};
