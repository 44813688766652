import React from 'react';
import { useEffect, useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";
import './LetsConnect.scss';

import accordionArrow from './../../assets/accordion-arrow.png'

const LetsConnect = ({ data }) => {
  const form = useRef();
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [token, setToken] = useState(null);
  const [submissionStatus, setSubmissionStatus] = useState(false);

  if (!data) return null;

  const { headline, body_copy, cta_label, submission_icon, submission_headline, submission_body_copy } = data;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(formData);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (token) {
      emailjs.sendForm('service_tdrndam', 'template_60l6fdz', form.current, 'AyfqozBH8VLDu5MM3')
      .then((result) => {
        console.log(result.text);
        setSubmissionStatus(true);
      }, (error) => {
        console.log(error.text);
      });
    }
  };

  function onChangeRecaptcha(value) {
    setToken(value);
  }

  return (
    <section className="lets-connect">
      <div className={`container ${isFormVisible ? 'active' : ''} ${submissionStatus ? 'submitted' : 'unsubmitted'}`}>
        <div className="form-content">
          <div className="lets-connect-container">
            <div className="lets-connect-intro">
              <h2 className="brand-experience-title" dangerouslySetInnerHTML={{ __html: headline }}></h2>
              <p className="body-copy" dangerouslySetInnerHTML={{ __html: body_copy }}></p>
            </div>
            <div className="lets-connect-cta">
              <button
                className="lets-connect-cta-button"
                onClick={() => setIsFormVisible(!isFormVisible)}
                aria-expanded={isFormVisible}
              >
                {cta_label}
                <div className="lets-connect-cta-arrow">
                  <img src={accordionArrow} alt="accordion arrow" />
                </div>
              </button>
            </div>
          </div>
          <div className={`contact-form-container ${isFormVisible ? 'active' : ''}`}>
            <form ref={form} onSubmit={sendEmail} className="contact-form">
              <div className="name-email-container">
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                    aria-required="true"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    aria-required="true"
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                  aria-required="true"
                />
              </div>
              <ReCAPTCHA
                sitekey="6LdLArsmAAAAADI9ka0T1-OiByt5enJN7hkiPlsi"
                onChange={onChangeRecaptcha}
              />
              <button type="submit" className="submit-button">
                Submit
              </button>
            </form>
          </div>
        </div>
        <div className="thank-you-message">
          <div className="thank-you-icon"><img src={submission_icon.url} alt="submission icon" /></div>
          <h2>{ submission_headline }</h2>
          <p dangerouslySetInnerHTML={{ __html: submission_body_copy }}></p>
        </div>
      </div>
    </section>
  );
};

export default LetsConnect;
