import { React } from 'react'
import { Navigate, Routes, Route, useLocation } from 'react-router-dom';
import { OptionsContextProvider } from './OptionsContext';

// Components
import Header from './Components/Header/Header'
import Footer from './Components/Footer/Footer'

// Pages
import Home from './pages/Home/Home'
import Portfolio from './pages/Portfolio/Portfolio'
import PortfolioItem from './pages/PortfolioItem/PortfolioItem'
// import TestPortfolio from './pages/Portfolio/TestPortfolio'
import CaseStudy from 'pages/CaseStudy/CaseStudy';
import CaseStudyItem from 'pages/CaseStudyItem/CaseStudyItem';
import Contact from 'pages/Contact/Contact';
import Company from './pages/Company/Company';
import JobPosting from 'pages/JobPosting/JobPosting';
import Culture from './pages/Culture/Culture';
import Newsletter from 'pages/Newsletter/Newsletter';
import HolidayGift from 'pages/HolidayGift/HolidayGift';
import HolidayGift2023 from 'pages/HolidayGift2023/index';
import HolidayGiftSlides from 'pages/HolidayGift2023/Slides/Slides';
import HolidayGift2024 from 'pages/HolidayGift2024/index';
import HolidayGift2024v2 from 'pages/HolidayGift2024/indexv2';
import CapabilitiesEmbed from 'pages/CapabilitiesEmbed/CapabilitiesEmbed';

import Financial from 'pages/Financial/Financial';

function App() {
  const location = useLocation();

   // List of paths where Header and Footer should not be displayed
  const pathsWithoutHeaderFooter = ['/holiday', '/financial'];

   // Check if the current path matches any in the list
  const hideHeaderFooter = pathsWithoutHeaderFooter.some(path => location.pathname.startsWith(path));


  return (
    <>
      <OptionsContextProvider>

        {!hideHeaderFooter && <Header />}

        <Routes>
          <Route path="/" element={<Navigate to='work' />} />
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="work" element={<Portfolio />} />
          <Route path="work/:slug" element={<PortfolioItem />} />
          {/* <Route path="case-studies" element={<CaseStudy />} /> */}
          {/* <Route path="case-studies/:slug" element={<CaseStudyItem />} /> */}
          {/* <Route path="capabilities" element={<CapabilitiesEmbed />} /> */}
          {/* <Route path="company" element={<Company />} /> */}
          {/* <Route path="careers/:slug" element={<JobPosting />} /> */}
          {/* <Route path="culture" element={<Culture />} /> */}
          {/* <Route path="contact" element={<Contact />} /> */}
          <Route path="newsletter" element={<Newsletter />} />
          {/* <Route path="holiday-2022" element={<HolidayGift />} />
          <Route exact path="holiday" element={<HolidayGift2023 />} />
          <Route path="holiday/slides" element={<HolidayGiftSlides />} />
          <Route path="holiday" element={<HolidayGift2024 />} /> */}
          {/* <Route path="holiday-2024v2" element={<HolidayGift2024v2 />} /> */}
          <Route path="financial" element={<Financial />} />
          {/* <Route path="portfoliotest" element={<TestPortfolio />} />  */}

          <Route path="*" element={<Navigate to='work' />} />
        </Routes>

        {!hideHeaderFooter && <Footer />}

      </OptionsContextProvider>
    </>
  );
}

export default App;
