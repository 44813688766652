import React, { useEffect, useState, useRef } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './WorkShowcase.scss';

import accordionArrow from './../../assets/accordion-arrow.png';

const WorkShowcase = ({ data }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(1); // Start at 1 (real first item)
  const [isTransitioning, setIsTransitioning] = useState(true);
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);
  const transitionRef = useRef(false); // Prevent duplicate calls

  useEffect(() => {
    const checkDevice = () => {
      const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      const isTabletViewport = window.innerWidth <= 1024;
      setIsMobileOrTablet(isMobileDevice || isTabletViewport);
    };

    checkDevice();
    window.addEventListener('resize', checkDevice);
    return () => window.removeEventListener('resize', checkDevice);
  }, []);

  if (!data) return null;

  const { headline, work_showcase_items } = data;

  // Clone first and last items for smooth looping
  const clonedItems = [
    work_showcase_items[work_showcase_items.length - 1], // Clone last item to the beginning
    ...work_showcase_items,
    work_showcase_items[0] // Clone first item to the end
  ];

  const handleCarouselChange = (index) => {
    if (transitionRef.current) return; // Prevent duplicate triggers
    transitionRef.current = true; // Lock transition

    setActiveItemIndex(index);

    setTimeout(() => {
      if (index === 0) {
        // If user reaches the fake first, jump to the real last
        setIsTransitioning(false);
        setActiveItemIndex(clonedItems.length - 2);
      } else if (index === clonedItems.length - 1) {
        // If user reaches the fake last, jump to the real first
        setIsTransitioning(false);
        setActiveItemIndex(1);
      } else {
        setIsTransitioning(true);
      }

      transitionRef.current = false; // Unlock transition
    }, 500); // Adjust to match transition time
  };

  return (
    <section className={`work-showcase ${isMobileOrTablet ? 'mobile' : ''}`}>
      <div className="container">
        <h2 className="work-showcase-title" dangerouslySetInnerHTML={{ __html: headline }}></h2>
        <div className="tabs">
          {work_showcase_items.map((item, index) => (
            <button
              key={index}
              className={`tab ${index === activeItemIndex - 1 ? 'active' : ''}`} // Offset by 1 since we start at index 1
              onClick={() => !transitionRef.current && setActiveItemIndex(index + 1)}
              aria-label={`Show ${item.showcase_headline}`}
              tabIndex={0}
            >
              <p className="work-showcase-title" dangerouslySetInnerHTML={{ __html: item.showcase_headline }}></p>
            </button>
          ))}
        </div>
      </div>
      <div className="container">
        <Carousel
          selectedItem={activeItemIndex}
          onChange={handleCarouselChange}
          showArrows={true}
          showStatus={false}
          showThumbs={false}
          infiniteLoop={false} // Disable built-in infinite loop
          className={`work-showcase-carousel ${isMobileOrTablet ? 'mobile' : ''}`}
          transitionTime={isTransitioning ? 500 : 0} // Disable animation for instant jumps
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            (
              <button
                type="button"
                onClick={() => !transitionRef.current && onClickHandler()}
                title={label}
                className="carousel-button prev"
                aria-label="Previous quote"
              >
                <img src={accordionArrow} alt="Previous" style={{ transform: 'rotate(90deg)' }} />
              </button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            (
              <button
                type="button"
                onClick={() => !transitionRef.current && onClickHandler()}
                title={label}
                className="carousel-button next"
                aria-label="Next quote"
              >
                <img src={accordionArrow} alt="Next" style={{ transform: 'rotate(-90deg)' }} />
              </button>
            )
          }
        >
          {clonedItems.map((item, index) => {
            const isColorDark = (color) => {
              if (color.includes('linear-gradient')) {
                const match = color.match(/#[a-fA-F0-9]{6}/);
                if (!match) return false;
                color = match[0];
              }

              const hex = color.replace('#', '');
              const r = parseInt(hex.substr(0, 2), 16);
              const g = parseInt(hex.substr(2, 2), 16);
              const b = parseInt(hex.substr(4, 2), 16);
              const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
              return luminance < 0.65;
            };

            const textColor = isColorDark(item.background_color) ? '#ffffff' : '#000000';

            return (
              <div
                key={index}
                className={`work-showcase-item ${item.showcase_headline.toLowerCase().replace(/\s+/g, '-')} ${index === activeItemIndex ? 'active' : ''}`}
                style={{
                  background: item.background_color,
                  color: textColor
                }}
              >
                <div className="work-showcase-image mobile">
                  <img src={item.showcase_image_desktop.url} alt={item.showcase_image_desktop.alt} />
                </div>
                <div className="work-showcase-content">
                  <h3
                    className="work-showcase-title"
                    dangerouslySetInnerHTML={{ __html: item.showcase_headline }}
                    style={{ color: textColor }}
                  ></h3>
                  <div
                    className="work-showcase-description"
                    dangerouslySetInnerHTML={{ __html: item.showcase_body_copy }}
                    style={{ color: textColor }}
                  ></div>
                </div>
                <div className="work-showcase-image desktop">
                  <img src={item.showcase_image_desktop.url} alt={item.showcase_image_desktop.alt} />
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </section>
  );
};

export default WorkShowcase;
