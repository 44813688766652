import React from 'react';
import { useEffect, useState } from 'react'
import './ClientTrust.scss'

const ClientTrust = ({ data }) => {
  if (!data) return null;

  const { headline, trust_items } = data;

  return (
    <section className="client-trust">
      <div className="container">
        <h2 className="client-trust-title" dangerouslySetInnerHTML={{ __html: headline }}></h2>
        <div className="trust-items">
          {trust_items.map((item, index) => (
            <div key={index} className="trust-item">
              {/* <div className="trust-item-icon">
                <img src={item.icon.url} alt={item.label} />
              </div> */}
              <h3 className="trust-item-label" dangerouslySetInnerHTML={{ __html: item.label }}></h3>
              <div className="trust-item-copy" dangerouslySetInnerHTML={{ __html: item.body_copy }}></div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ClientTrust;
