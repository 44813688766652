import React, { useState, useEffect } from 'react';
import './Hero.scss';
import Logo from './../../assets/3rc-logo_white.png';
import keepScrolling from './../../assets/keep-scrolling.png';

const Hero = ({ data }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (!data) return null;

  const { page_name, page_sub_headline, background_image } = data;

  return (
    <section className="hero">
      <div className="background-media">
        <img src={background_image.url} alt="Financial Hero" />
      </div>
      <div className="container">
        <div className="logo">
          <img src={Logo} alt="Third Rail Creative Logo" />
        </div>
        <div className="main-hero-content">
          <div className="hero-title-container">
            {page_sub_headline && <h3 className="hero-sub-headline" dangerouslySetInnerHTML={{ __html: page_sub_headline }}></h3>}
            <h1 className="hero-title" dangerouslySetInnerHTML={{ __html: page_name }}></h1>
          </div>
          <div className={`scroll-message`}>
            <div className={`scroll-caret ${isScrolled ? 'hide' : ''}`}><img src={keepScrolling} alt="Keep Scrolling" /></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
