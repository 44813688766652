import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { API_URL } from 'assets';
import _ from 'lodash';
import './NewsletterDisplay.scss'

import AccordionCarousel from './components/AccordionCarousel';
import VerticalQuotes from './components/VerticalQuotes';
import FullWidthImage from './components/FullWidthImage';

import ModalVideo from 'react-modal-video';
import '/node_modules/react-modal-video/scss/modal-video.scss';

export default function NewsletterDisplay() {
  const [newsletterData, setNewsletterData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isPreview = params.get('preview') === 'true';

  useEffect(() => {
    const hash = window.location.hash;
    const sectionId = hash.slice(1); // Remove the '#' character

    const scrollToSection = () => {
      if (sectionId) {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
          sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        // Scroll to the top if no sectionId is provided
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    };

    // Delay scroll action to ensure component has fully mounted
    const timeout = setTimeout(() => {
      scrollToSection();
    }, 100); // Adjust the delay as needed

    // Cleanup function
    return () => clearTimeout(timeout);
  }, []);


  const Entry = ({ data, index }) => {
    const [isOpen, setOpen] = useState(false);
    const [expanded, setExpanded] = useState(index < 3);

    const entryData = data.acf.components[0];

    const dateString = entryData.posted_date;
    const parts = dateString.split("/");
    const date = new Date(parts[2], parts[1] - 1, parts[0]);
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

    const hasBfeElement = React.useMemo(() => {
      return entryData.layout_type === 'option2' || entryData.layout_type === 'option3' ||
             (entryData.layout_type === 'option4' && entryData.multi_block_layout.blocks.some(block => block.block_display === "copy_block"));
    }, [entryData]);

    const toggleExpand = () => {
      setExpanded(!expanded);
    };

    return (
      <div className={`entry ${entryData.layout_type}`} id={data.title.rendered.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase()} key={data.title.rendered}>
        <div className="container">
          {entryData.layout_type === 'option1' ?
            <>
              <div className={`content-container ${entryData.standard_layout.featured_image.url ? "with-featured-image" : ''}`}>
                <div className="entry-content">
                  <div className={`tag ${entryData.tag}`}>{entryData.tag}</div>
                  <p className="date">{formattedDate}</p>
                  <h2 dangerouslySetInnerHTML={{ __html: entryData.headline }}></h2>
                  {entryData.standard_layout.featured_media === "image" ?
                    <>
                      {entryData.standard_layout.featured_image.url ?
                        <div className={`entry-media mobile-display ${entryData.standard_layout.featured_media_display}`}>
                          <img src={entryData.standard_layout.featured_image.url} alt={entryData.headline} />
                        </div>
                        : null
                      }
                    </>
                    : entryData.standard_layout.featured_media === "video" ?
                      <div className={`entry-media mobile-display ${entryData.standard_layout.featured_media_display}`}>
                        <video muted playsInline autoPlay loop>
                          <source src={entryData.standard_layout.featured_video_file_mp4} type="video/mp4; codecs=hvc1" />
                          <source src={entryData.standard_layout.featured_video_file_webm} type="video/webm" />
                        </video>
                      </div>
                      : null
                  }
                  <p className="body-copy" dangerouslySetInnerHTML={{ __html: entryData.standard_layout.body_copy }}></p>
                  {entryData.standard_layout.featured_video_cta_copy.length ?
                    <>
                      <ModalVideo
                        channel={entryData.standard_layout.featured_video_platform}
                        youtube={{ mute: 0, autoplay: 0 }}
                        isOpen={isOpen}
                        videoId={entryData.standard_layout.featured_video_id}
                        onClose={() => setOpen(false)}
                      />
                      <button className="btn-primary open-video-modal" onClick={() => setOpen(true)}>
                        {entryData.standard_layout.featured_video_cta_copy}
                      </button>
                    </>
                    : null
                  }
                </div>
                {entryData.standard_layout.featured_media === "image" ?
                  <>
                    {entryData.standard_layout.featured_image.url ?
                      <div className={`entry-media desktop-display ${entryData.standard_layout.featured_media_display}`}>
                        <img src={entryData.standard_layout.featured_image.url} alt={entryData.headline} />
                      </div>
                      : null
                    }
                  </>
                  : entryData.standard_layout.featured_media === "video" ?
                    <div className={`entry-media desktop-display ${entryData.standard_layout.featured_media_display}`}>
                      <video muted playsInline autoPlay loop>
                        <source src={entryData.standard_layout.featured_video_file_mp4} type="video/mp4; codecs=hvc1" />
                        <source src={entryData.standard_layout.featured_video_file_webm} type="video/webm" />
                      </video>
                    </div>
                    : null
                }
              </div>
              {entryData.standard_layout.additional_images.length ?
                <div className="additional-images">
                  {entryData.standard_layout.additional_images.map((image, index) => (
                    <div className="additional-image" key={image.image.url + index}>
                      <img src={image.image.url} alt={image.image.alt} />
                    </div>
                  ))}
                </div>
                : null
              }
            </>
            : entryData.layout_type === 'option2' ?
              <>
                <div className={`content-container`}>
                  <div className="entry-content">
                    <div className="post-header">
                      <div className={`tag ${entryData.tag}`}>{entryData.tag}</div>
                      <p className="date">{formattedDate}</p>
                      <h2 dangerouslySetInnerHTML={{ __html: entryData.headline }}></h2>
                    </div>
                    {expanded || !hasBfeElement ? (
                      <>
                        {entryData.multi_point_layout.points.map((point, index) => (
                          <div className="point bfe" key={point.subheader + index}>
                            <div className="image" key={point.image.url + index}>
                              <img src={point.image.url} />
                            </div>
                            <div className="point-copy">
                              <p dangerouslySetInnerHTML={{ __html: point.body_copy }} key={point.body_copy + index}></p>
                              {point.featured_video_cta_copy.length ?
                                <>
                                  <ModalVideo
                                    channel={point.featured_video_platform}
                                    youtube={{ mute: 0, autoplay: 0 }}
                                    isOpen={isOpen}
                                    videoId={point.featured_video_id}
                                    onClose={() => setOpen(false)}
                                  />
                                  <button className="btn-primary open-video-modal" onClick={() => setOpen(true)}>
                                    {point.featured_video_cta_copy}
                                  </button>
                                </>
                                : null
                              }
                            </div>
                          </div>
                        ))}
                        {hasBfeElement && <button className="read-more btn-primary" onClick={toggleExpand}>Read less</button>}
                      </>
                    ) : (
                      <>
                        <div className="point bfe" key={entryData.multi_point_layout.points[0].subheader}>
                          <div className="image" key={entryData.multi_point_layout.points[0].image.url}>
                            <img src={entryData.multi_point_layout.points[0].image.url} />
                          </div>
                          <div className="point-copy">
                            <p dangerouslySetInnerHTML={{ __html: entryData.multi_point_layout.points[0].body_copy }}></p>
                            {entryData.multi_point_layout.points[0].featured_video_cta_copy.length ?
                              <>
                                <ModalVideo
                                  channel={entryData.multi_point_layout.points[0].featured_video_platform}
                                  youtube={{ mute: 0, autoplay: 0 }}
                                  isOpen={isOpen}
                                  videoId={entryData.multi_point_layout.points[0].featured_video_id}
                                  onClose={() => setOpen(false)}
                                />
                                <button className="btn-primary open-video-modal" onClick={() => setOpen(true)}>
                                  {entryData.multi_point_layout.points[0].featured_video_cta_copy}
                                </button>
                              </>
                              : null
                            }
                          </div>
                        </div>
                        <button className="read-more btn-primary" onClick={toggleExpand}>Read more</button>
                      </>
                    )}
                  </div>
                </div>
              </>
              : entryData.layout_type === 'option3' ?
                <div className="content-container">
                  <div className="entry-content">
                    <div className={`tag ${entryData.tag}`}>{entryData.tag}</div>
                    <p className="date">{formattedDate}</p>
                    <h2 dangerouslySetInnerHTML={{ __html: entryData.headline }}></h2>
                    {expanded || !hasBfeElement ? (
                      <>
                        {entryData.sub_entries_layout.sub_entries.map((subentry, index) => (
                          <div className="subentry bfe" key={subentry.subheader}>
                            <div className="image">
                              <img src={subentry.image.url} />
                            </div>
                            <div className="subentry-content">
                              {subentry.subheader ?
                                <h3 dangerouslySetInnerHTML={{ __html: subentry.subheader }}></h3>
                                : null
                              }
                              <div className="sub-entry">
                                <p dangerouslySetInnerHTML={{ __html: subentry.body_copy }} key={subentry.body_copy + index}></p>
                                {subentry.featured_video_cta_copy.length ?
                                  <>
                                    <ModalVideo
                                      channel={subentry.featured_video_platform}
                                      youtube={{ mute: 0, autoplay: 0 }}
                                      isOpen={isOpen}
                                      videoId={subentry.featured_video_id}
                                      onClose={() => setOpen(false)}
                                    />
                                    <button className="btn-primary open-video-modal" onClick={() => setOpen(true)}>
                                      {subentry.featured_video_cta_copy}
                                    </button>
                                  </>
                                  : null
                                }
                              </div>
                            </div>
                          </div>
                        ))}
                        {hasBfeElement && <button className="read-more btn-primary" onClick={toggleExpand}>Read less</button>}
                      </>
                    ) : (
                      <>
                        <div className="subentry bfe" key={entryData.sub_entries_layout.sub_entries[0].subheader}>
                          <div className="image">
                            <img src={entryData.sub_entries_layout.sub_entries[0].image.url} />
                          </div>
                          <div className="subentry-content">
                            {entryData.sub_entries_layout.sub_entries[0].subheader ?
                              <h3 dangerouslySetInnerHTML={{ __html: entryData.sub_entries_layout.sub_entries[0].subheader }}></h3>
                              : null
                            }
                            <div className="sub-entry">
                              <p dangerouslySetInnerHTML={{ __html: entryData.sub_entries_layout.sub_entries[0].body_copy }}></p>
                              {entryData.sub_entries_layout.sub_entries[0].featured_video_cta_copy.length ?
                                <>
                                  <ModalVideo
                                    channel={entryData.sub_entries_layout.sub_entries[0].featured_video_platform}
                                    youtube={{ mute: 0, autoplay: 0 }}
                                    isOpen={isOpen}
                                    videoId={entryData.sub_entries_layout.sub_entries[0].featured_video_id}
                                    onClose={() => setOpen(false)}
                                  />
                                  <button className="btn-primary open-video-modal" onClick={() => setOpen(true)}>
                                    {entryData.sub_entries_layout.sub_entries[0].featured_video_cta_copy}
                                  </button>
                                </>
                                : null
                              }
                            </div>
                          </div>
                        </div>
                        <button className="read-more btn-primary" onClick={toggleExpand}>Read more</button>
                      </>
                    )}
                  </div>
                </div>
                : entryData.layout_type === 'option4' ?
                  <div className={`content-container`}>
                    <div className="entry-content">
                      <div className={`tag ${entryData.tag}`}>{entryData.tag}</div>
                      <p className="date">{formattedDate}</p>
                      <h2 dangerouslySetInnerHTML={{ __html: entryData.headline }}></h2>
                      <div className="block-container">
                        {expanded || !hasBfeElement ? (
                          <>
                            {entryData.multi_block_layout.blocks.map((block, index) => (
                              <React.Fragment key={`block-${index}`}>
                                {block.block_display === "copy_block" ?
                                  <div className={`block bfe article-copy-block ${block.optional_image.url ? "with-image" : ""} ${block.block_quote ? "with-quote" : ""} ${block.full_width_copy_block ? "full-width-copy-block" : ""} ${block.include_divider_line ? "include-divider-line" : ""}`}>
                                    {block.subheader ?
                                      <h3 dangerouslySetInnerHTML={{ __html: block.subheader }}></h3>
                                      : null
                                    }
                                    {block.body_copy ?
                                      <div className={`body-copy ${block.optional_image.url ? "with-image" : ""} ${block.smaller_image_display ? "smaller-image" : ""} ${block.copy_and_image_reverse ? "reverse-display" : ""} ${block.mobile_copy_and_image_reverse ? "mobile-reverse-display" : ""} ${block.image_alignment} ${block.block_quote ? "with-quote" : ""}`}>
                                        <p dangerouslySetInnerHTML={{ __html: block.body_copy }}></p>
                                        {block.optional_image.url ?
                                          <img src={block.optional_image.url} />
                                          : null
                                        }
                                        {block.block_quote ?
                                          <div className="block-quote">
                                            <p dangerouslySetInnerHTML={{ __html: block.block_quote }}></p>
                                          </div>
                                          : null
                                        }
                                      </div>
                                      : null
                                    }
                                  </div>
                                  : block.block_display === "accordion_carousel_block" ?
                                    <div className="block accordion-carousel-block">
                                      <AccordionCarousel content={block} />
                                    </div>
                                    : block.block_display === "vertical_quotes_block" ?
                                      <div className="block vertical-quotes-block">
                                        <VerticalQuotes content={block} />
                                      </div>
                                      : block.block_display === "full_width_image" ?
                                        <div className="block full-width-image-block">
                                          <FullWidthImage content={block} />
                                        </div>
                                        : null
                                }
                              </React.Fragment>
                            ))}
                            {hasBfeElement && <button className="read-more btn-primary" onClick={toggleExpand}>Read less</button>}
                          </>
                        ) : (
                          <>
                            {entryData.multi_block_layout.blocks.find(block => block.block_display === "copy_block") && (
                              <div className={`block bfe article-copy-block ${entryData.multi_block_layout.blocks[0].optional_image.url ? "with-image" : ""}`}>
                                {entryData.multi_block_layout.blocks[0].subheader ?
                                  <h3 dangerouslySetInnerHTML={{ __html: entryData.multi_block_layout.blocks[0].subheader }}></h3>
                                  : null
                                }
                                {entryData.multi_block_layout.blocks[0].body_copy ?
                                  <div className={`body-copy ${entryData.multi_block_layout.blocks[0].optional_image.url ? "with-image" : ""} ${entryData.multi_block_layout.blocks[0].smaller_image_display ? "smaller-image" : ""} ${entryData.multi_block_layout.blocks[0].copy_and_image_reverse ? "reverse-display" : ""} ${entryData.multi_block_layout.blocks[0].mobile_copy_and_image_reverse ? "mobile-reverse-display" : ""} ${entryData.multi_block_layout.blocks[0].image_alignment}`}>
                                    <p dangerouslySetInnerHTML={{ __html: entryData.multi_block_layout.blocks[0].body_copy }}></p>
                                    {entryData.multi_block_layout.blocks[0].optional_image.url ?
                                      <img src={entryData.multi_block_layout.blocks[0].optional_image.url} />
                                      : null
                                    }
                                  </div>
                                  : null
                                }
                              </div>
                            )}
                            <button className="read-more btn-primary" onClick={toggleExpand}>Read more</button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  : null
          }
        </div>
      </div>
    )
  }

  useEffect(() => {
    const getContent = async () => {
      const postResponse = await fetch(`${API_URL}/newsletter`);
      const item = await postResponse.json();
      setNewsletterData(item);
    }
    getContent();
  }, []);

  useEffect(() => {
    if (newsletterData) {
      const newData = isPreview ? newsletterData : newsletterData.filter(entry => entry.acf.components[0].preview_only === false);
      setFilteredData(newData);
    }
  }, [newsletterData])

  if (!filteredData) return null;

  return (
    <section className="newsletter-display">
      {filteredData.map((entry, index) => (
        <Entry data={entry} key={index} index={index} />
      ))}
    </section>
  )
}
