import React from 'react';
import { useEffect, useState } from 'react'
import './Footer.scss'

const Footer = ({ data }) => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  if (!data) return null;

  const { headline, company_logo, body_copy, cta, featured_image, social_links } = data;

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="upper-footer">
            {/* <h2 className="footer-title" dangerouslySetInnerHTML={{ __html: headline }}></h2> */}
            <div className="company-logo">
              <img src={company_logo.url} alt="Company Logo" />
            </div>
            <div className="contact">
              <div className="contact-content">
                <p className="body-copy" dangerouslySetInnerHTML={{ __html: body_copy }}></p>
                {/* <div className="cta">
                  <a href={cta.url} className="cta-link" dangerouslySetInnerHTML={{ __html: cta.title }}></a>
                </div> */}
              </div>
            </div>
          </div>
          <div className="lower-footer">
            <div className="social">
              <p className="social-links-title">Follow us on social</p>
              <div className="social-links">
                {social_links.map((link, index) => (
                  <a href={link.link} key={index} target="_blank" rel="noopener noreferrer">
                    {link.platform}
                  </a>
                ))}
              </div>
            </div>
            <div className="timezones">
              <div className="timezone">
                <p className="timezone-label">PST:</p>
                <p className="timezone-time">
                  {currentTime.toLocaleTimeString('en-US', {
                    timeZone: 'America/Los_Angeles',
                    hour: 'numeric',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true
                  })}
                </p>
              </div>
              <div className="timezone">
                <p className="timezone-label">CST:</p>
                <p className="timezone-time">
                  {currentTime.toLocaleTimeString('en-US', {
                    timeZone: 'America/Chicago',
                    hour: 'numeric',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true
                  })}
                </p>
              </div>
              <div className="timezone">
                <p className="timezone-label">EST:</p>
                <p className="timezone-time">
                  {currentTime.toLocaleTimeString('en-US', {
                    timeZone: 'America/New_York',
                    hour: 'numeric',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true
                  })}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
