import React from 'react';
import { useEffect, useState } from 'react'
import './Services.scss'

import accordionArrow from './../../assets/accordion-arrow.png'

const Services = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const { headline, services_list } = data;

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      handleAccordionClick(index);
    }
  };

  if (!data) return null;

  return (
    <section className="services">
        {headline && (
          <div className="container">
            <h2 className="services-title" dangerouslySetInnerHTML={{ __html: headline }}></h2>
          </div>
        )}
        <div className="service-list-container">
          {services_list.map((item, index) => (
            <div
              key={index}
              className={`service-list-item ${activeIndex === index ? 'active' : ''}`}
              onClick={() => handleAccordionClick(index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              role="button"
              tabIndex={0}
              aria-expanded={activeIndex === index}
              aria-controls={`service-content-${index}`}
            >
              <div className="container">
                <div className="service-name-container">
                  <h3 className="service-name">{item.service_name}</h3>
                  <div className="content-wrapper">
                    <p className="service-description" dangerouslySetInnerHTML={{ __html: item.service_description }}></p>
                    <div className="service-details-content-wrapper">
                      <div className="service-details-content">
                        <div className="details">
                        <p dangerouslySetInnerHTML={{ __html: item.service_details }}></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img className="accordion-arrow" src={accordionArrow} alt="accordion arrow" />
                </div>
                <div className="service-details">
                  <ul className="sub-services-listing">
                    {item.sub_service_listing && item.sub_service_listing.length > 0 && item.sub_service_listing.map((subService, subIndex) => (
                      <li key={subIndex} className="sub-service-item" dangerouslySetInnerHTML={{ __html: subService.sub_service }}>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
    </section>
  );
};

export default Services;

/* SCSS Updates:
@import '/src/styles/variables.scss';

.services {
  padding: 100px 0;

  h2 {
    text-align: center;
    display: block;
    margin: 0 auto 100px;
  }

  .service-list-container {
    .service-list-item {
      padding: 0 50px 80px;
      margin-top: -50px;
      cursor: pointer;
      transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
      border-radius: 50px 50px 0px 0px;
      background: #F4F4F4;
      box-shadow: 4px 0px 13px 0px rgba(0, 0, 0, 0.25);

      .service-name-container {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 60px 0 20px;

        @media (max-width: $breakpoint-mobile) {
          flex-direction: column;
        }

        .service-name {
          width: 50%;
          position: relative;
          padding-right: 30px;
          transition: color 0.5s cubic-bezier(0.4, 0, 0.2, 1);
          color: #000;
          font-family: "Helvetica Neue", sans-serif;
          font-size: 75px;
          font-style: normal;
          font-weight: 700;
          line-height: 80px;
          letter-spacing: -0.97px;

          @media (max-width: $breakpoint-tablet) {
            font-size: 50px;
            line-height: 55px;
          }

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            font-size: 35px;
            line-height: 40px;
          }
        }

        .content-wrapper {
          display: flex;
          flex-direction: column;
          width: 50%;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
          }

          .service-description {
            font-family: 'ThePicnicClub', serif;
            font-size: 35px;
            font-style: normal;
            font-weight: 400;
            line-height: 42px;
            margin-bottom: 20px;

            @media (max-width: $breakpoint-tablet) {
              font-size: 25px;
              line-height: 32px;
            }

            @media (max-width: $breakpoint-mobile) {
              font-size: 22px;
              line-height: 28px;
            }
          }

          .service-details-content {
            .details {
              p {
                font-size: 20px;
                line-height: 25px;

                @media (max-width: $breakpoint-mobile) {
                  font-size: 18px;
                  line-height: 22px;
                }
              }
            }

            .interactive-projects {
              display: flex;
              flex-direction: row;
              gap: 20px;
              margin-top: 30px;

              @media (max-width: $breakpoint-mobile) {
                flex-direction: column;
              }

              .listing {
                width: 50%;

                @media (max-width: $breakpoint-mobile) {
                  width: 100%;
                }

                h4 {
                  margin-bottom: 10px;
                }

                .project-listing {
                  list-style: none;
                  padding-left: 0;

                  .project-name {
                    font-size: 15px;
                    color: #000;
                    text-decoration: none;

                    @media (max-width: $breakpoint-tablet) {
                      line-height: 15px;
                      margin-bottom: 10px;
                      display: inline-block;
                    }
                  }
                }
              }

              .featured-project-image {
                width: 50%;

                @media (max-width: $breakpoint-mobile) {
                  width: 100%;
                }

                img {
                  width: 100%;
                  height: auto;
                }
              }
            }
          }
        }

        .accordion-arrow {
          width: 40px;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);

          @media (max-width: $breakpoint-mobile) {
            width: 30px;
            top: 45%;
          }
        }
      }

      .service-details {
        max-height: 0;
        overflow: hidden;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
        opacity: 0;
        margin: 0;
        visibility: hidden;
        transform: translateY(-20px);

        .sub-services-listing {
          width: 50%;
          column-count: 2;
          column-gap: 0;
          list-style: disc;
          padding-left: 20px;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            column-count: 1;
          }

          li {
            margin-bottom: 10px;
            width: 70%;
          }
        }
      }

      &.active {
        .service-name-container {
          .accordion-arrow {
            transform: translateY(-50%) rotate(45deg) scale(1.1);
          }
        }

        .service-details {
          max-height: 2000px;
          opacity: 1;
          margin: 20px 0 50px;
          visibility: visible;
          transform: translateY(0);
        }
      }

      &:hover {
        padding: 10px 50px 80px;
      }
    }
  }
}
*/
