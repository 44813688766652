import React, { useEffect, useState, useRef } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './BrandExperience.scss';

import accordionArrow from './../../assets/accordion-arrow.png';

const BrandExperience = ({ data }) => {

  const { headline, body_copy, quote_display } = data;
  const [activeItemIndex, setActiveItemIndex] = useState(1);
  const [isTransitioning, setIsTransitioning] = useState(true);
  const transitionRef = useRef(false);

  // Clone first and last items for smooth looping
  const clonedQuotes = [
    quote_display[quote_display.length - 1],
    ...quote_display,
    quote_display[0]
  ];

  const handleCarouselChange = (index) => {
    if (transitionRef.current) return;
    transitionRef.current = true;

    setActiveItemIndex(index);

    setTimeout(() => {
      if (index === 0) {
        setIsTransitioning(false);
        setActiveItemIndex(clonedQuotes.length - 2);
      } else if (index === clonedQuotes.length - 1) {
        setIsTransitioning(false);
        setActiveItemIndex(1);
      } else {
        setIsTransitioning(true);
      }
      transitionRef.current = false;
    }, 500);
  };


  if (!data) return null;

  return (
    <section className="brand-experience">
      <div className="container">
        <h2 className="brand-experience-title" dangerouslySetInnerHTML={{ __html: headline }}></h2>
        <p className="body-copy" dangerouslySetInnerHTML={{ __html: body_copy }}></p>
        <div className="quote-display-container">
          <Carousel
            selectedItem={activeItemIndex}
            onChange={handleCarouselChange}
            showThumbs={false}
            showStatus={false}
            infiniteLoop={false}
            autoPlay={false}
            transitionTime={isTransitioning ? 500 : 0}
            swipeable={true}
            emulateTouch={true}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              (
                <button
                  type="button"
                  onClick={() => !transitionRef.current && onClickHandler()}
                  title={label}
                  className="carousel-button prev"
                  aria-label="Previous quote"
                >
                  <img src={accordionArrow} alt="Previous" style={{ transform: 'rotate(90deg)' }} />
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              (
                <button
                  type="button"
                  onClick={() => !transitionRef.current && onClickHandler()}
                  title={label}
                  className="carousel-button next"
                  aria-label="Next quote"
                >
                  <img src={accordionArrow} alt="Next" style={{ transform: 'rotate(-90deg)' }} />
                </button>
              )
            }
          >
            {clonedQuotes.map((quote, index) => (
              <div key={index} className="quote-display-item">
                <div className='quote'>
                  <p dangerouslySetInnerHTML={{ __html: quote.quote }}></p>
                </div>
                {quote.quote_attribution && (
                  <p className="quote-attribution" dangerouslySetInnerHTML={{ __html: quote.quote_attribution }}></p>
                )}
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default BrandExperience;
