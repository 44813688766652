import React, { useState, useEffect } from 'react';
import './NationallyRecognized.scss';

const NationallyRecognized = ({ data }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (!data) return null;

  const { headline, text_block_1, text_block_2 } = data;

  return (
    <section className="nationally-recognized">
      <div className="container">
        <div className="main-hero-content">
          <div className={`hero-headline-container ${isScrolled ? 'show' : ''}`}>
            <div className="headline-dec">
              <div className="dec-circle"></div>
              <div className="dec-line"></div>
            </div>
            <h2 className="hero-headline" dangerouslySetInnerHTML={{ __html: headline }}></h2>
          </div>
        </div>
        <div className="additional-hero-content">
          <div className="text-block-1">
            {text_block_1?.block_image?.url &&
              <div className="block-image">
                <img src={text_block_1.block_image.url} alt="" />
              </div>
            }
            <p dangerouslySetInnerHTML={{__html: text_block_1.body_copy}}></p>
          </div>
          <div className="text-block-2">
            {text_block_2?.block_image?.url &&
              <img className="block-image" src={text_block_2.block_image.url} alt="" />
            }
            <p dangerouslySetInnerHTML={{__html: text_block_2.body_copy}}></p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NationallyRecognized;
