import React from 'react';
import { useEffect, useState } from 'react'
import './Expertise.scss'

const Expertise = ({ data }) => {
  if (!data) return null;

  const { headline, body_copy, list_headline, expertise_list, featured_image, featured_image_mobile } = data;

  return (
    <section className="expertise">
      <div className="container">
        <div className="expertise-content">
          <h2 className="expertise-title" dangerouslySetInnerHTML={{ __html: headline }}></h2>
          <p className="body-copy" dangerouslySetInnerHTML={{ __html: body_copy }}></p>
          <div className="expertise-list-container">
            <h3 className="list-headline" dangerouslySetInnerHTML={{ __html: list_headline }}></h3>
            <ul className="expertise-list">
              {expertise_list.map((item, index) => (
                <li key={index} className="expertise-list-item" dangerouslySetInnerHTML={{ __html: item.expertise_item }}></li>
              ))}
            </ul>
          </div>
        </div>
        <div className="expertise-image">
          <img src={featured_image.url} alt={featured_image.alt} className="desktop-image"/>
          <img src={featured_image_mobile.url} alt={featured_image_mobile.alt} className="mobile-image"/>
        </div>
      </div>
    </section>
  );
};

export default Expertise;
